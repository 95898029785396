.label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 4rem;
}

.validation {
  color: var(--color-button);
  margin-top: 1rem;
  text-align: justify;
}

.come-back {
  color: var(--color-secondary);
  font-weight: 700;
  font-size: 1.7rem;
  margin: 1rem;
  text-align: center;
}

.text-field {
  width: 70vw;
  max-width: 500px;
}

.text-field input {
  color: white;
}

.text-field .MuiFormControl-root {
  margin-top: 1.5rem;
}

.text-field .MuiInput-underline:after {
  border-bottom: 2px solid var(--color-button) !important;
}

#ResetPassword .passwordButton{
  cursor: pointer;
}

#ResetPassword .text-field .MuiInput-underline:before {
  border-bottom: 1px solid var(--color-button) !important;
}
#ResetPassword .text-field .MuiFormLabel-root,
.text-field.MuiButtonBase-root {
  color: var(--color-secondary) !important;
}
#ResetPassword .text-field .MuiFormLabel-root {
  margin-left: 3rem;
}
#ResetPassword .text-field .MuiInputLabel-shrink {
  transform: scale(0.85) !important;
}
#ResetPassword .text-field .MuiFormLabel-root.Mui-focused {
  color: var(--color-secondary) !important;
}

#ResetPassword .text-field .MuiInputAdornment-root .MuiSvgIcon-root,
.text-field .value-group svg,
.MuiInputAdornment-root svg {
  font-size: 2rem;
  color: var(--color-button);
}

#ResetPassword .button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70vw;
  max-width: 500px;
}

#ResetPassword .button button {
  font-weight: 700;
  color: var(--color-button);
  border-radius: 0.4rem;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #07d8d6;
  border: 0px;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100px;
  height: 50px;
}

#ResetPassword .button button.full-width {
  width: 70vw;
  max-width: 500px;
}

#ResetPassword .button button svg {
  font-size: 2rem;
}

#ResetPassword .button button:hover {
  background-color: #06bfbc;
}

#ResetPassword .button button.prev {
  background-color: #0000;
  color: var(--color-secondary);
  border: 2px solid var(--color-secondary);
}

#ResetPassword .link-button {
  text-decoration: none;
  display: flex;
  font-weight: 700;
  color: var(--color-button);
  border-radius: 0.4rem;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #07d8d6;
  border: 0px;
  margin-top: 2rem;
  align-items: center;
  justify-content: space-evenly;
  width: 100rem;
  height: 50px;
}

#ResetPassword .link-button:hover {
  background-color: #06bfbc;
}

#ResetPassword .prev {
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000;
  transition: background-color 0.3s;
  color: var(--color-secondary);
  border: 2px solid var(--color-secondary);
  height: 5rem;
  width: 100%;
  border-radius: 0.5rem;
  text-decoration: none;
  margin-top: 30px;

}

#ResetPassword .prev:hover {
  background-color: #06bfbc11;
}

#ResetPassword .prev .MuiSvgIcon-root {
  margin-right: 1.5rem;
}

#ResetPassword .icon {
  width: 2rem;
}

