:root {
  font-size: 60%;
  --color-background: #f9f8f6;
  --color-primary-light: #6e4184;
  --color-primary: #524a7f;
  --color-button-text: #525382;
  --color-secondary: #07d8d6;
  --color-form-icon: #000;
  --color-form-secondary-text: #a7a5a5;
  --color-tab-text: #4b4a7d;
  --color-selected-tab: #ce3ba5;
  --color-header-background: #5d4781;
  --color-button: #f6f5f2;
  --color-form-text: #683b84;
  --color-result-background: #edebe5;
  --color-result-card-background: #f6f5f2;
  --color-summary-background: #614183b3;
  --color-installment-label: #5b4f86;
  --color-unavailable-filter: #b8b7b333;
  --color-error-text: #f44336;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
}

body {
  background: url("./assets/img/background.png") no-repeat,
    linear-gradient(#000, var(--color-primary));
  background-size: cover;
  background-position-x: 35%;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

body,
input,
button,
textarea {
  font: 500 1.6rem Lato;
}

.MuiFormLabel-root,
.MuiInputBase-input {
  font-size: 1.6rem !important;
  font-weight: 500 !important;
  font-family: Lato !important;
}

.container {
  width: 100vw;
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dealer-bar {
  height: 50px;
  padding: 20px;
  background-color: var(--color-primary);
  color: #fff;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 700px) {
  :root {
    font-size: 62.5%;
  }
}
